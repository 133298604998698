
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import { WorkingHour } from '@/interfaces';
  import formModal from '@/mixins/formModal';

  @Component
  export default class WorkingHoursModal extends Mixins(formModal) {
    @Prop({ default: () => ({}) }) declare readonly item: WorkingHour[];

    get weekdays(): string[]{
      return this.$t('restaurants.days') as unknown as string[];
    }

    get defaultHours(): Partial<WorkingHour>[] {
      return Array(7).fill({}).map((v, day) => ({
        day,
        active: false,
        from: '00:00',
        to: '00:00'
      }));
    }

    mounted(): void {
      this.data = this.defaultHours;

      if (this.item.length) {
        this.item.forEach(item => {
          this.data[item.day] = {
            ...item,
            active: true,
            from: this.removeSeconds(item.from),
            to: this.removeSeconds(item.to)
          };
        });
      }
    }

    removeSeconds(val: string): string {
      if (val.length !== 8) {
        return val;
      }

      const elements = val.split(':');
      elements.pop();
      return elements.join(':');
    }

    removeDisabledDaysAndSave(): void {
      this.data = this.data.filter(item => item.active);
      this.save();
    }
  }
