
  import { turningOffReasonsValues } from '@/interfaces';
import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class TurningOffReasonsSwitch extends Vue {
    @Prop({ default: false }) value!: boolean;
    @Prop({ default: '' }) label!: string;

    menuLevel: 1 | 2 = 1;
    showMenu = false;

    selectedDuration = '';

    get menuHeader(): string {
      return this.menuLevel === 1 ? 'turnOffDuration' : 'turnOffReason'
    }

    get menuItems(): Record<string, string> {
      if (this.menuLevel === 1) {
        return this.$t('restaurants.turningOffDurations') as unknown as Record<string, string>;
      } else {
        return turningOffReasonsValues.reduce((all, item) => ({
          ...all,
          [item]: this.$t(`restaurants.turningOffReasons.${ item }`),
      }), {})
      }
    }

    menuItemClick(value: string): void {
      if (this.menuLevel === 1) {
        this.selectedDuration = value;
      } else {
        this.$emit('selected', { value: false, reason: value, duration: this.selectedDuration } )
        this.showMenu = false;
      }

      this.menuLevel = this.menuLevel === 1 ? 2 : 1;
    }
  }
