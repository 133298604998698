
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ConceptModel } from '@/models';
import { toLocale } from '@/helpers/date';

import RestaurantStatus from '@/components/buttons/RestaurantStatus.vue';
import RestaurantPosStatus from '@/components/buttons/RestaurantPosStatus.vue';
import SynchronizeBtn from '@/components/buttons/SynchronizeBtn.vue';
import RestaurantStatusSwitch from './RestaurantStatusSwitch.vue';
import TimeIcon from './TimeIcon.vue';

@Component({
  components: {
    RestaurantStatus,
    RestaurantPosStatus,
    SynchronizeBtn,
    RestaurantStatusSwitch,
    TimeIcon,
  },
})
export default class ConceptStatus extends Vue {
  @Prop({ required: true }) readonly concept!: ConceptModel;

  get turnedOffUntil(): string | null {
    if (!this.concept.storeStatus) {
      return null;
    }

    const until = Object.values(this.concept.storeStatus)?.[0]?.until;
    return until ? toLocale(until) : null;
  }
}
