
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { Concept, Location } from '@/interfaces';
  import ConceptModal from '@/components/modals/Concept.vue';
  import ConceptStatus from '@/components/ConceptStatus.vue';
  import CheckAccess from '@/components/CheckAccess.vue';
  import DotIndicator from "@/components/DotIndicator.vue";

  const conceptsStore = namespace('concepts');

  @Component({ components: {DotIndicator, ConceptModal, ConceptStatus, CheckAccess } })
  export default class Concepts extends Vue {
    @Prop({ required: true }) readonly location!: Location;
    @Prop() readonly mode!: number;

    showModal = false;
    itemToEdit: Concept | null = null;

    get locationId(): number {
      return this.location.id;
    }

    @conceptsStore.State
    readonly concepts!: Concept[];

    mounted(): void {
      this.getConcepts(this.locationId);
    }

    @conceptsStore.Action
    getConcepts!: (locationId: number) => Promise<void>

    @conceptsStore.Action
    removeConcept!: (id: number) => Promise<void>;

    @conceptsStore.Action
    updateConcept!: (data: Concept) => Promise<void>;

    @conceptsStore.Action
    addConcept!: (data: { locationId: number; data: Partial<Concept> }) => Promise<void>;

    remove(id: number): void {
      if (confirm(this.$t('restaurants.confirmRemConcept') as string)) {
        this.removeConcept(id);
      }
    }

    submit(data: Concept | Partial<Concept>): void {
      const { locationId } = this;
      !this.itemToEdit?.id
        ? this.addConcept({ locationId, data })
        : this.updateConcept(data as Concept)
    }

    showEditModal(item: Concept): void {
      this.itemToEdit = item;
      this.showModal = true;
    }

    hideEditModal(): void {
      this.itemToEdit = null;
      this.showModal = false;
    }
  }
