
  import { Prop, Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import TurningOffReasonsSwitch from './TurningOffReasons.vue';
  import { TurningOffReasonsResult } from '@/interfaces';
  import { ChangeStatusLocationPayload } from '@/store/interfaces';

  const conceptsStore = namespace('concepts');

  @Component({ components: { TurningOffReasonsSwitch } })
  export default class LocationStatusSwitch extends Vue {
    @Prop({ required: true }) readonly locationId!: number;
    @Prop({ required: true }) readonly hasAllRestaurantsActive!: boolean;

    @conceptsStore.Action
    changeStatusLocation!: (data: ChangeStatusLocationPayload) => Promise<void>

    switchStatus({ value, reason, duration }: TurningOffReasonsResult): void {
      if (!confirm(this.$t(`locations.${ value ? 'confirmOn' : 'confirmOff' }`) as string)) return;

      this.changeStatusLocation({ locationId: this.locationId, status: value, reason, duration });
    }
  }
