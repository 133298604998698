
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SupportedProviders } from '@/interfaces';
import { ChangeConceptStatusPayload } from '@/store/interfaces';

const appStore = namespace('app');
const conceptsStore = namespace('concepts');

@Component
export default class RestaurantStatus extends Vue {
  @Prop({ required: true }) readonly conceptId!: number;

  status: Partial<Record<SupportedProviders, boolean>> = {};

  @appStore.State('providersList')
  readonly providers!: SupportedProviders[];

  @appStore.Getter
  readonly isRebel!: boolean;

  get statusIcon(): 'mdi-pause' | 'mdi-play' | 'mdi-play-pause' {
    return this.allOnline ? 'mdi-pause' : this.allOffline ? 'mdi-play' : 'mdi-play-pause';
  }

  get allOnline(): boolean {
    return Object.values(this.status)
      .filter((item) => item)
      .every((item) => item);
  }

  get allOffline(): boolean {
    return Object.values(this.status)
      .filter((item) => item)
      .every((item) => !item);
  }

  get hasData(): boolean {
    return Object.keys(this.status).length > 0;
  }

  async mounted(): Promise<void> {
    this.status = await this.getStatus(this.conceptId);
  }

  @conceptsStore.Action
  changeStatus!: (data: ChangeConceptStatusPayload) => void;

  @conceptsStore.Action('getRestaurantStatus')
  getStatus!: (conceptId: number) => Promise<Partial<Record<SupportedProviders, boolean>>>;

  switchStatus(provider?: SupportedProviders): void {
    const isOnline = !provider ? this.allOnline : this.status[provider];

    if (!confirm(this.$t(`restaurants.${isOnline ? 'turnOff' : 'turnOn'}`) as string)) return;

    this.updateStatus(!isOnline, provider);
    this.changeStatus({
      id: this.conceptId,
      status: !isOnline,
      provider,
    });
  }

  updateStatus(status: boolean, provider: SupportedProviders | null = null): void {
    if (provider) {
      this.status[provider] = status;
    } else {
      Object.keys(this.status).forEach((provider) => {
        this.status[provider] = status;
      });
    }
  }
}
