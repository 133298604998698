
import { namespace } from 'vuex-class';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import FormModal from '@/mixins/formModal';
import {
  isValidatStringNumber,
  isValidBoltStoreId,
  isValidStavaStoreId,
  isValidUUID,
  isValidWoltStoreId,
} from '@/helpers/validations';
import { Concept, Location } from '@/interfaces';

const conceptsStore = namespace('concepts');

@Component
export default class ConceptModal extends Mixins(FormModal) {
  @Prop({ required: true }) readonly location!: Location;

  //? TODO - fix wolt issues

  concepts: Concept[] = [];

  get locationId(): number {
    return this.location.id;
  }

  async mounted(): Promise<void> {
    this.concepts = this.editMode
      ? [
          {
            name: this.item.name as string,
            id: this.item.restaurantId as number,
          } as unknown as Concept,
        ]
      : await this.getConceptsToAdd(this.location);
  }

  @conceptsStore.Action
  getConceptsToAdd!: (location: Location) => Promise<Concept[]>;

  isValidatStringNumber(value: string): boolean {
    return Boolean(!value || isValidatStringNumber(value));
  }

  isValidUUID(value: string): boolean {
    return Boolean(!value || isValidUUID(value));
  }

  isValidStavaStoreId(value: string): boolean {
    return Boolean(!value || isValidStavaStoreId(value));
  }

  isValidBoltStoreId(value: string): boolean {
    return Boolean(!value || isValidBoltStoreId(value));
  }

  isValidWoltStoreId(value: string): boolean {
    return Boolean(!value || isValidWoltStoreId(value));
  }
}
