
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import {
  SupportedProviders,
  TurningOffDuration,
  TurningOffReason,
  TurningOffReasonsResult,
} from '@/interfaces';
import { ChangeConceptStatusPayload } from '@/store/interfaces';
import { ConceptModel } from '@/models';
import MarketplaceIcon from './MarketplaceIcon.vue';
import TurningOffReasonsSwitch from './TurningOffReasons.vue';
import { RestaurantStatusQueue } from '@/services/providers';

const appStore = namespace('app');
const conceptsStore = namespace('concepts');

@Component({ components: { MarketplaceIcon, TurningOffReasonsSwitch } })
export default class RestaurantStatusSwitch extends Vue {
  @Prop({ required: true }) readonly concept!: ConceptModel;
  @Prop({ required: true }) readonly showOnlyIcons!: boolean;

  private interval = 0;

  get selectAllSwitchLabel(): string {
    return this.showOnlyIcons
      ? `restaurants.${
          !this.concept.hasAtLeastOneEnabledStore ? 'restaurantIsOff' : 'restaurantIsOn'
        }`
      : 'general.all';
  }

  get selectAllSwitchSelected(): boolean {
    return this.showOnlyIcons
      ? this.concept.hasAtLeastOneEnabledStore
      : this.concept.areAllStoresEnabled;
  }

  get hasData(): boolean {
    return !!Object.values(this.concept?.storeStatus || []).length;
  }

  async mounted(): Promise<void> {
    this.hasData || this.getStatus(this.concept.id);
  }

  beforeDestroy(): void {
    clearInterval(this.interval);
  }

  @appStore.State('providersList')
  readonly providers!: SupportedProviders[];

  @conceptsStore.Action('getRestaurantStatus')
  getStatus!: (conceptId: number) => Promise<void>;

  @conceptsStore.Action
  changeStatus!: (data: ChangeConceptStatusPayload) => void;

  @conceptsStore.Action
  getRestaurantQueueStatus!: (conceptId: number) => Promise<RestaurantStatusQueue>;

  async switchStatus(
    provider?: SupportedProviders,
    reason?: TurningOffReason,
    duration?: TurningOffDuration
  ): Promise<void> {
    const isActive = provider
      ? this.concept.storeIsEnabledIn(provider)
      : this.selectAllSwitchSelected;

    if (!provider && isActive && !reason) {
      throw new Error('Reason has to be set for global status change');
    }

    if (!confirm(this.$t(`restaurants.${isActive ? 'turnOff' : 'turnOn'}`) as string)) {
      return;
    }

    this.changeStatus({
      id: this.concept.id,
      status: !isActive,
      provider: provider || undefined,
      reason,
      duration,
    });
  }

  async onRestaurantstatusChanged({ reason, duration }: TurningOffReasonsResult): Promise<void> {
    return this.switchStatus(undefined, reason, duration);
  }

  formatTimeDifference(timestamp?: string): string {
    if (!timestamp) return '';
    const now = new Date();
    const lastUpdated = new Date(timestamp);
    const difference = now.getTime() - lastUpdated.getTime();

    const minutes = Math.floor(difference / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} days ago`;
    if (hours > 0) return `${hours} hours ago`;
    if (minutes > 0) return `${minutes} minutes ago`;
    return `just now`;
  }
}
