import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { User } from "@/interfaces";
import { AddUserPayload, UpdateUserPayload } from "@/store/interfaces";
import UserModal from '@/components/modals/User.vue';

const usersStore = namespace('users');

@Component({ components: { UserModal } })
export default class UsersList extends Vue {
  @Prop({ default: null }) readonly locationId!: number;
  
  showModal = false;
  itemToEdit: User | null = null;

  @usersStore.State
  readonly users!: User[];
  
  mounted(): void {
    this.getUsers(this.locationId);
  }

  @usersStore.Action
  getUsers!: (locationId?: number) => Promise<void>;

  @usersStore.Action
  removeUser!: (id: number) => Promise<void>

  @usersStore.Action
  updateUser!: (data: UpdateUserPayload) => Promise<void>;

  @usersStore.Action
  addUser!: (data: AddUserPayload) => Promise<void>;

  remove(id: number): void {
    if (confirm(this.$t('users.removeConfirm') as string)) {
      this.removeUser(id);
    }
  }

  submit(user: User | Partial<User>): void {
    const payload = {
      user: {
        ...user,
        role: user.role || 'user',
      },
      locationId: this.locationId,
    };

    user.id ? this.updateUser(payload as { user: User } ) : this.addUser(payload)
  }

  add(): void {
    this.itemToEdit = null;
    this.showModal = true;
  }

  edit(item: User): void  {
    this.itemToEdit = item;
    this.showModal = true;
  }
}
