
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import formModal from '@/mixins/formModal';
  import CountriesFilter from '@/components/filters/Countries.vue';
  import ResourcesFilter from '@/components/filters/Resources.vue';
  import { ResourceType, Role, roles, TextValuePair } from '@/interfaces';

  const appStore = namespace('app');

  @Component({ components: { CountriesFilter, ResourcesFilter } })
  export default class UserModal extends Mixins(formModal) {
    @Prop({ default: false }) withoutRoles!: boolean;

    mounted(): void {
      if (this.item?.resources?.length) {
        this.data.resourcesIds = this.item.resources?.map(item => item.resourceId);
      }
    }

    @appStore.Getter
    readonly userRole!: Role;

    get filteredRoles(): readonly Role[] {
      if (this.userRole === 'brand') {
        return ['brand'];
      }

      return roles;
    }

    get roles(): TextValuePair[] {
      return this.filteredRoles
        .filter(role => role !== 'user')
        .map(role => ({
          text: this.$t(`users.roles.${ role }`) as string,
          value: role,
        }));
    }

    get resourceType(): ResourceType {
      return this.data.role === 'regional' ? 'region' : 'brand';
    }

    @Watch('resourceType')
    resourceTypeChanged(): void {
      this.data.resources = [];
    }

    isRequiredForBrandAndRegionalManager(resourcesIds: number[]): boolean {
      return !(['brand', 'region'] as Role[]).includes(this.data.role) || resourcesIds.length > 0;
    }

    isRequiredForCountryManager(countries: string[]): boolean {
      return (this.data.role as Role) !== 'country' || countries.length > 0;
    }


  }
