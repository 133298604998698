
  import { namespace } from 'vuex-class';
  import { Component, Vue } from 'vue-property-decorator';

  import { Location, LocationDelivery, WorkingHour } from '@/interfaces';
  import { UpdateDeliveryPayload, UpdateHoursInLocationPayload } from '@/store/interfaces';
  import { LocationStatusModel, ConceptModel } from '@/models';

  import LocationModal from '@/components/modals/Location.vue';
  import WorkingHoursModal from '@/components/modals/WorkingHours.vue';
  import Files from '@/components/Files.vue';
  import Concepts from '@/components/Concepts.vue';
  import Users from '@/components/Users.vue';
  import SynchronizeBtn from '@/components/buttons/SynchronizeBtn.vue';
  import LocationDeliveryModal from '@/components/modals/LocationDelivery.vue';
  import LocationStatusSwitch from '@/components/LocationStatusSwitch.vue';
  import CheckAccess from '@/components/CheckAccess.vue';

  const locationsStore = namespace('locations');
  const conceptsStore = namespace('concepts');

  @Component({ 
    components: {
      LocationModal, Files, Concepts, Users, WorkingHoursModal, SynchronizeBtn, LocationDeliveryModal, LocationStatusSwitch,
      CheckAccess,
    }
  })
  export default class LocationPage extends Vue {
    location: Location | null = null;;
    editLocationModal = false;
    showWorkingHoursModal = false;
    editDeliveryModal = false;

    get locationId(): number {
      return +this.$route.params.id;
    }

    get hasAllRestaurantsActive(): boolean {
      return (new LocationStatusModel('', this.concepts)).hasAllRestaurantsActive()
    }

    @conceptsStore.State
    readonly concepts!: ConceptModel[];

    async mounted(): Promise<void> {
      this.location = await this.getLocation(this.locationId);
    }

    @locationsStore.Action
    getLocation!: (id: number) => Promise<Location>

    @locationsStore.Action
    removeLocation!: (id: number) => Promise<void>

    @locationsStore.Action
    updateLocation!: (data: Location) => Promise<Location>

    @locationsStore.Action
    updateDelivery!: (data: UpdateDeliveryPayload) => Promise<void>

    @conceptsStore.Action
    updateHoursInLocation!: (data: UpdateHoursInLocationPayload) => Promise<void>

    remove(): void {
      if (confirm(this.$t('restaurants.removeConfirm') as string)) {
        this.location && this.removeLocation(this.location.id);
        this.$router.push({ name: 'locations' }).catch(() => null);
      }
    }

    async update(data: Location): Promise<void> {
      this.location = await this.updateLocation(data);
    }

    saveWorkingHours(data: WorkingHour[]): void {
      const { locationId } = this;
      this.updateHoursInLocation({ locationId, data });
    }

    updateDeliveryData(data: LocationDelivery[]): void {
      this.location && this.updateDelivery({ locationId: this.location.id, data })
    }
  }
