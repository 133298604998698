
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SupportedProviders } from '@/interfaces';
import { ChangeConceptStatusPayload } from '@/store/interfaces';

const appStore = namespace('app');
const conceptsStore = namespace('concepts');

@Component
export default class RestaurantPosStatus extends Vue {
  @Prop({ required: true }) readonly conceptId!: number;

  status: Record<SupportedProviders, boolean> = {
    UberEats: false,
    SocialFood: false,
    Glovo: false,
    Bolt: false,
    Wolt: false,
    JustEat: false,
  };

  @appStore.State('providersList')
  readonly providers!: SupportedProviders[];

  @appStore.Getter
  readonly isRebel!: boolean;

  get statusIcon(): 'mdi-lan-disconnect' | 'mdi-lan-check' | 'mdi-lan-connect' {
    return this.allConnected
      ? 'mdi-lan-disconnect'
      : this.allDisconnected
      ? 'mdi-lan-check'
      : 'mdi-lan-connect';
  }

  get allConnected(): boolean {
    return Object.values(this.status)
      .filter((item) => item !== null)
      .every((item) => item === true);
  }

  get allDisconnected(): boolean {
    return Object.values(this.status)
      .filter((item) => item !== null)
      .every((item) => item === false);
  }

  get hasData(): boolean {
    return Object.keys(this.status).length > 0;
  }

  async mounted(): Promise<void> {
    this.status = await this.getPosStatus(this.conceptId);
  }

  @conceptsStore.Action
  changePosStatus!: (data: ChangeConceptStatusPayload) => Promise<void>;

  @conceptsStore.Action
  getPosStatus!: (conceptId: number) => Promise<Record<SupportedProviders, boolean>>;

  switchStatus(provider?: SupportedProviders): void {
    const allConnected = !provider ? this.allConnected : this.status[provider];

    if (
      !confirm(
        this.$t(
          `restaurants.${allConnected ? 'integrationOffConfirm' : 'integrationOnConfirm'}`
        ) as string
      )
    ) {
      return;
    }

    this.updateStatus(!allConnected, provider);

    this.changePosStatus({
      id: this.conceptId,
      status: !allConnected,
      provider,
    });
  }

  updateStatus(status: boolean, provider: SupportedProviders | null = null): void {
    if (provider) {
      this.status[provider] = status;
    } else {
      Object.keys(this.status).forEach((provider) => {
        this.status[provider] = status;
      });
    }
  }
}
